import i18next, {i18n} from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {usePersistedState} from "../hooks";
import {merge, uniq} from "lodash";
import CudaMessages from "./CudaMessages";

export type Resources = {
    [key: string]: any
};
export type Namespaces = {
    [key: string]: Resources
}
export type LanguageMessages = {
    [key: string]: Namespaces
}

export interface I18nextProviderProps {
    /** the i18next messages to initiate with (merged on top of CudaMessages) */
    messages?: LanguageMessages,
    /** children to render inside the Provide */
    children?: React.ReactNode
}

export interface I18nContextProps {
    /** the i18next instance **/
    i18next?: i18n,
    /** add to messages. Please use this over calling the i18next directly **/
    addToMessages?: (messages?: LanguageMessages) => void,
    /** change current. Please use this over calling the i18next directly **/
    setActiveLanguage?: (language: string) => void,
    /** the current active language **/
    activeLanguage: string,
    /** the currently available languages **/
    availableLanguages: string[]
}

export const I18nContext = createContext<I18nContextProps>({
    activeLanguage: "en",
    availableLanguages: ["en"]
});

export const I18nextProvider = ({messages, children}: I18nextProviderProps) => {
    const initialMessages = merge(CudaMessages, messages);
    i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources: initialMessages,
            fallbackLng: "en",
            keySeparator: ".",
            nsSeparator: "|",
            interpolation: {
                escapeValue: false
            },
            // In case top-level keys are provided to translate (e.g. "cuda", or "cuda.actions") instead of a full specific identifier, just return the key.
            returnedObjectHandler: (key) => key
        });
    const initialLanguage = Object.keys(initialMessages).find((language) => i18next.language.startsWith(language)) || "en";
    const [activeLanguage = initialLanguage, setActiveLanguage] = usePersistedState<string>("language", initialLanguage);
    const [availableLanguages, setAvailableLanguages] = useState(Object.keys(initialMessages).sort());

    const addToMessages = useCallback((newMessages?: LanguageMessages): void => {
        if (!newMessages) return;

        Object.keys(newMessages).forEach((language) => {
            Object.keys(newMessages[language]).forEach((namespace) => {
                i18next.addResourceBundle(language, namespace, newMessages[language][namespace], true, true);
            });
        });

        setAvailableLanguages(((currentLanguages) => uniq([...currentLanguages, ...Object.keys(newMessages)]).sort()));
    }, []);

    useEffect(() => {
        i18next.changeLanguage(activeLanguage);
    }, [activeLanguage]);

    return (
        <I18nContext.Provider value={{i18next, addToMessages, activeLanguage, setActiveLanguage, availableLanguages}}>
            {children}
        </I18nContext.Provider>
    );
};

export const useI18next = () => useContext(I18nContext);